<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M2.716 19.293l8.577-8.586a1 1 0 0 0 0-1.414L2.716.708A1 1 0 0 0 1.302.707l-.593.59a1 1 0 0 0-.001 1.415l.001.001L8.01 10l-7.3 7.287a1 1 0 0 0 0 1.415l.593.591a1 1 0 0 0 1.414 0z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
